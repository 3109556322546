<template>
  <div class="resourcesinfo">
    <div class="resourcesinfo_center" v-if="resourcesdata!==null">
      <header>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: `${showp}`}" v-if="showone==0||showone==1">{{shown}}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/resources' }" v-else>资源资料</el-breadcrumb-item>

          <el-breadcrumb-item>{{ resourcesdata.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </header>
      <main>
        <div class="main_title">
          <div class="m_text">
            <!-- <i></i> -->
            <span class="tst">{{resourcesdata.title}}</span>
            <span class="m_suffix">{{resourcesdata.suffix}}</span>
          </div>
          <div class="h_icon" v-if="showone!=0">
            <p class="h_p_like">
              <i class="like" v-if="!praiseFlag" @click="messagePraise"></i>
              <i class="likes" v-else @click="deletePraise"></i>
              <span>{{resourcesdata.praiseCount}}</span>
            </p>
            <p class="h_p_star">
              <i class="star" v-if="!collectionFlag" @click="messageCollection"></i>
              <i class="stars" v-else @click="messageDeleteCollection"></i>
              <span>{{resourcesdata.collectionCount}}</span>
            </p>
          </div>
        </div>
        <div class="main_info">
          <p>类型：{{resourcesdata.resourceType}}</p>
          <p>文件大小：{{resourcesdata.resouceSize}}{{resourcesdata.resouceSizeUnit}}</p>
          <p>科目：{{resourcesdata.subjectName}}</p>
        </div>
        <div class="main_one">
          <span>学段：{{resourcesdata.periodName}}</span>
        </div>
        <div class="main_two">
          <span>年级：{{resourcesdata.gradeName}}</span>
        </div>
        <div class="main_footer">
          <p>评论数：{{resourcesdata.commentCount}}</p>
          <p>下载量：{{resourcesdata.downCount}}</p>
          <p>上传者：{{resourcesdata.createName}}</p>
          <p>上传时间：{{resourcesdata.createTime}}</p>
        </div>
      </main>
      <aside>
        <div
          v-if="resourcesdata.suffix=='jpg'||resourcesdata.suffix=='png'||resourcesdata.suffix=='svg'||resourcesdata.suffix=='gif'"
          style="display: flex;justify-content:center;align-items: center;"
        >
          <img :src="resourcesdata.resource[0].resouceUrl" alt />
        </div>
        <div v-else-if="resourcesdata.suffix=='mp4'">
          <video
            :src="resourcesdata.resource[0].resouceUrl"
            controls
            autoplay
            style="background-color:rgb(0,0,0);width:100%;height:100%;display:block"
          ></video>
        </div>
        <div v-else-if="resourcesdata.suffix=='mp3'">
          <audio :src="resourcesdata.resource[0].resouceUrl" controls autoplay></audio>
        </div>
        <div v-else-if="resourcesdata.suffix=='pdf'">
          <iframe :src="resourcesdata.resource[0].resouceUrl" frameborder="0" width="100%" height="600"></iframe>
        </div>
        <div
          v-else-if="resourcesdata.suffix=='doc'||resourcesdata.suffix=='docx'||resourcesdata.suffix=='xls'|| resourcesdata.suffix=='xlsx'||resourcesdata.suffix=='ppt'||resourcesdata.suffix=='pptx'"
        >
          <iframe
            :src="'https://view.officeapps.live.com/op/view.aspx?src='+resourcesdata.resource[0].resouceUrl"
            frameborder="0"
            width="100%"
            height="600"
          ></iframe>
        </div>
        <div v-else-if="resourcesdata.suffix=='txt'">
          <iframe :src="resourcesdata.resource[0].resouceUrl" frameborder="0" width="100%" height="600"></iframe>
        </div>
        <div v-else style="display: flex;justify-content: center;line-height: 432px;">
          <p>该文件类型不支持查看，请下载后查看</p>
        </div>
        <!-- <el-button type="primary" @click="aaaa(resourcesdata.resource[0].resouceUrl)" style="cursor: pointer;margin:0 auto;">点击预览</el-button> -->
      </aside>
      <div class="asdie_brns">
        <el-button type="primary" size="mini" @click="downLoad(resourcesdata.resource[0])">下载文件</el-button>
      </div>
      <footer>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="资源介绍" name="first">
            <p>{{resourcesdata.conent}}</p>
          </el-tab-pane>
          <el-tab-pane label="评论评价" name="second" v-if="showone!=0">
            <h3>评论评价</h3>
            <div class="det_foot">
              <el-input type="textarea" :rows="5" placeholder="发表您的看法......" v-model="textarea"></el-input>
              <el-button type="primary" @click="releaseComment" size="mini" disable>发表评论</el-button>
            </div>
            <div class="s_text" v-for="item in evalData" :key="item.id">
              <div class="text_left">
                <div class="member_flex" v-if="item.avager==null">
                  <img src="../../../assets/components/userin.png" alt />
                </div>
                <div class="member_flex" v-else>
                  <img :src="item.avager" alt />
                </div>
                <p class="user_name">{{item.createUser}}</p>
              </div>
              <div class="text_right">
                <div class="r_content">
                  <p>{{item.message}}</p>
                </div>
                <div class="r_time">
                  <span>{{item.createTime}}</span>
                </div>
              </div>
            </div>
            <div class="block" v-if="this.evalData!==null">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage1"
                :page-size="5"
                layout="total, prev, pager, next, jumper"
                :total="total"
                background
              ></el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // tabs标签
      activeName: "second",
      // 资源数据
      resourcesdata: null,
      // 点赞
      collectionFlag: null, //收藏
      praiseFlag: null, //点赞
      //
      // 发表评论
      textarea: null,
      //评论列表
      evalData: null,
      // 分页
      total: null,
      currentPage1: 1, //页数
      //
      showone: null,
      showp: null,
      shown: null,
    };
  },
  created() {
    // console.log(this.$route.query.id, "资源详情route123456");
    this.showp = window.sessionStorage.getItem("defaultindex");
    this.shown = window.sessionStorage.getItem("defaultname");
    this.showone = this.$route.query.cardsite;
    this.queryResourceDetail(this.$route.query.id);
    //
    // 点赞和收藏
    this.queryMessageStatus(this.$route.query.id);
    //
    this.queryCourseDetailComment(this.$route.query.id);
  },
  methods: {
    aaaa(url) {
      // window.location =
      //   "http://192.168.110.246:8012/onlinePreview?url=" +
      //   encodeURIComponent(this.$Base64.encode(url));
      // return;
      window.open(
        "http://192.168.110.246:8012/onlinePreview?url=" +
          encodeURIComponent(this.$Base64.encode(url))
      );
    },
    // 资源详情
    async queryResourceDetail(id) {
      let form = new FormData();
      form.append("resourceId", id);
      await this.$Http.queryResourceDetails(form).then((res) => {
        console.log(res, "资源详情");
        let aData = JSON.parse(JSON.stringify(res.data));
        aData.periodName = aData.periodName.join("/");
        aData.gradeName = aData.gradeName.join("/");
        aData.subjectName = aData.subjectName.join("/");
        this.resourcesdata = aData;
      });
    },
    // 点赞
    async messagePraise() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messagePraise({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "点赞");
        this.queryMessageStatus(id);
        this.queryResourceDetail(id);
      }
    },
    // 取消点赞
    async deletePraise() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.deletePraise({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "取消点赞");
        this.queryMessageStatus(id);
        this.queryResourceDetail(id);
      }
    },
    // 收藏
    async messageCollection() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messageCollection({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "收藏");
        this.queryMessageStatus(id);
        this.queryResourceDetail(id);
      }
    },
    // 取消收藏
    async messageDeleteCollection() {
      let id = this.$route.query.id;
      const { data, status } = await this.$Http.messageDeleteCollection({
        serviceId: id,
        type: 1,
      });
      if (status == 200) {
        // console.log(data, status, "取消收藏");
        this.queryMessageStatus(id);
        this.queryResourceDetail(id);
      }
    },
    // 查询点赞和收藏
    async queryMessageStatus(id) {
      const { data, status } = await this.$Http.queryMessageStatus({
        serviceId: id,
        type: 1, //1-普通课程/资料 2-系列课程 3-教研圈 4-活动 5-广场
      });
      if (status == 200) {
        console.log(data, status, "点赞和收藏");
        this.collectionFlag = data.collectionFlag;
        this.praiseFlag = data.praiseFlag;
      }
    },

    // 获取评论
    async queryCourseDetailComment(id) {
      const { data, status } = await this.$Http.queryCourseDetailComment({
        // id:id,//评论ID
        courseId: id - 0, //课程ID
        // resourceId: id, //资源id
        pageNo: this.currentPage1, // 页码
        pageSize: 5, //每页的数量
      });
      if (status == 200) {
        console.log(status, data, "评论");
        if (data == null) {
          this.evalData = data;
          this.total = 0;
        } else {
          this.evalData = data.list;
          this.total = data.total;
        }
      }
    },
    // 发布评论
    async releaseComment() {
      const { data, status } = await this.$Http.releaseComments({
        // commentId: commentId, //
        // createUser: createUser, //
        message: this.textarea, //评论信息
        serviceId: this.$route.query.id, //资源Id
        // serviceId: this.$route.query.id, //课程ID
        // type: type, //
      });
      if (status == 200) {
        console.log(status, data, "发布评论");
        this.textarea = null;
        this.queryCourseDetailComment(this.$route.query.id);
      }
    },

    //下载
    async downLoad(item) {
      console.log("下载", item);
      let pardel = { url: item.resouceUrl };
      this.$axios({
        method: "get",
        url: "./api/file/downLoad",
        params: pardel,
        responseType: "blob",
      }).then(async (res) => {
        console.log(res, "下载文件");
        if (!res) {
          this.$message.error("文件获取失败");
          return;
        } else {
          await this.$Http
            .collect({
              resourceId: item.resouceId, //下载资源的id
              serviceId: this.$route.query.id, //业务id
              type: 1, //业务类型 1-课程/资料 2-教研圈
            })
            .then((result) => {
              console.log(result, "下载统计");
            });
        }
        if (res.status == 200) {
          console.log("开始下载");
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          let fileNames = item.resouceName;
          link.setAttribute("download", fileNames);
          document.body.appendChild(link);
          link.click();
        }
      });
    },

    // 切换tabs
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.queryCourseDetailComment(this.$route.query.ids);
    },
  },
};
</script>
<style lang="scss" scoped>
.resourcesinfo {
  .resourcesinfo_center {
    width: 1200px;
    margin: 0 auto;
    cursor: pointer;
    header {
      padding: 15px 0;
    }
    main {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      min-height: 180px;
      background-color: #fff;
      padding: 0 30px;
      // margin: 0 30px;
      border-bottom: 1px solid #d7dce4;

      .main_title {
        display: flex;
        justify-content: space-between;
        .m_text {
          .tst {
            display: inline-block;
            // width: 360px;
            // height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 600;
            color: #141516;
            // line-height: 21px;
          }
          .m_suffix {
            display: inline-block;
            margin-left: 20px;
            // width: 25px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 19px;
            background: #ff8938;
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            padding: 0px 5px;
            // width: 44px;
            height: 19px;
          }
        }
        .h_icon {
          display: flex;
          justify-content: flex-start;
          .h_p_like {
            .like {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/blanklike.png") no-repeat;
            }
            .likes {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/solidlike.png") no-repeat;
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
          .h_p_star {
            margin-left: 40px;

            .star {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/blankstar.png") no-repeat;
            }
            .stars {
              cursor: pointer;
              display: inline-block;
              width: 30px;
              height: 27px;
              background: url("../../../assets/icon/solidestar.png") no-repeat;
            }
            span {
              display: inline-block;
              //   width: 29px;
              //   height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              //   line-height: 19px;
              vertical-align: super;
            }
          }
        }
      }
      .main_info {
        display: flex;
        justify-content: flex-start;
        p {
          margin-right: 30px;
          // width: 84px;
          // height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
          // line-height: 14px;
        }
      }
      .main_one {
        // width: 60px;
        // height: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5d6066;
        // line-height: 14px;
      }
      .main_two {
        // width: 72px;
        // height: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5d6066;
        // line-height: 14px;
      }
      .main_footer {
        display: flex;
        justify-content: flex-start;
        p {
          margin-right: 36px;
          // width: 77px;
          // height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
          // line-height: 14px;
        }
      }
    }
    aside {
      min-height: 432px;
      background-color: #fff;
      padding: 10px 30px;
      // display: flex;
      // justify-content: center;
      // //
      // align-items: center;
      // text-align: center;
      font-weight: 600;
      font-size: 28px;
      color: #5d6066;
      // div {
      //   display: flex;
      //   align-items: center;
      // }
    }
    .asdie_brns {
      background-color: #fff;
      padding: 10px 30px;
      display: flex;
      justify-content: flex-end;
    }
    footer {
      background-color: #fff;
      padding: 15px 20px;
      margin-top: 20px;
      ::v-deep .el-tabs__item {
        font-size: 15px;
        font-weight: 600;
      }
      h3 {
        padding: 10px 0;
        border-bottom: 1px solid #d7dce4;
      }
      .det_foot {
        margin-top: 20px;
        .el-button {
          margin-top: 10px;
          float: right;
        }
        .el-textarea {
          ::v-deep .el-textarea__inner {
            background: rgba(25, 119, 249, 0.06);
          }
        }
      }
      .s_text {
        width: 1140px;
        height: 110px;
        padding: 20px 10px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d7dce4;

        .text_left {
          width: 150px;
          height: 110px;
          .member_flex {
            display: flex;
            justify-content: center;
          }
          .user_name {
            margin-top: 10px;
            text-align: center;
            // width: 42px;
            // height: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #141516;
            // line-height: 16px;
          }
        }
        .text_right {
          width: 1039px;
          height: 110px;
          padding: 0 15px;
          .r_content {
            height: 70px;
            p {
              // width: 98px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              // line-height: 16px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
          }
          .r_time {
            // height: 10px;
            display: flex;
            justify-content: flex-end;
            // width: 67px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
            // line-height: 14px;
          }
        }
      }
      .block {
        padding: 20px 10px 0;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
